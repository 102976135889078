<template>
  <div>
    <b-container class="p-5">
      <h3>修改密码</h3>
      <b-card class="w-50 p-3 mt-5 shadow">
        <PasswordBox :id="staff.id"></PasswordBox>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import PasswordBox from "@/components/Modify/passwordBox";

export default {
  name: "ModifyPassword",
  components: {PasswordBox},
  data() {
    return {
      staff: {},
      old: '',
      newPassword: '',
      confirm: ''
    }
  },
  mounted() {
    this.staff = JSON.parse(localStorage.getItem('staffInfo'))
  }
}
</script>

<style scoped>

</style>
