import { render, staticRenderFns } from "./passwordBox.vue?vue&type=template&id=128c5f40&scoped=true&"
import script from "./passwordBox.vue?vue&type=script&lang=js&"
export * from "./passwordBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128c5f40",
  null
  
)

export default component.exports