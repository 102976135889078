<template>
  <div>
    <label for="input-live">旧密码</label>
    <b-form-input
        id="input-live"
        v-model="old"
        :state="nameState"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your old password"
        trim
    ></b-form-input>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback id="input-live-feedback">
      请输入5-15个字符
    </b-form-invalid-feedback>
    <label for="input-live">新密码</label>
    <b-form-input
        type="password"
        id="input-live"
        v-model="newPassword"
        :state="passwordState"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your new password"
        trim
    ></b-form-input>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback id="input-live-feedback">
      请输入5-15个字符
    </b-form-invalid-feedback>
    <label for="input-live">确认密码</label>
    <b-form-input
        type="password"
        id="input-live"
        v-model="confirm"
        :state="confirmState"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter your new password again"
        trim
    ></b-form-input>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback id="input-live-feedback">
      两次输入密码不一致
    </b-form-invalid-feedback>

    <!-- This is a form text block (formerly known as help block) -->
    <b-form-text id="input-live-help">请确保两次输入相同的密码</b-form-text>
    <div style="text-align: center">
      <b-button class="w-50 mx-auto mt-3" @click="modifyPassword()">提交</b-button>
    </div>
  </div>
</template>

<script>
import {changePassword} from "@/api";

export default {
  name: "passwordBox",
  props: {id: Number},
  computed: {
    nameState() {
      return this.old.length > 4 && this.old.length < 16
    },
    passwordState() {
      return this.newPassword.length > 4 && this.newPassword.length < 16
    },
    confirmState() {
      return this.confirm.length > 5 && this.newPassword === this.confirm
    }
  },
  data() {
    return {
      old: '',
      newPassword: '',
      confirm: ''
    }
  },
  mounted() {
    this.staff = JSON.parse(localStorage.getItem('staffInfo'))
  },
  methods: {
    modifyPassword() {
      if (this.passwordState && this.confirmState) {
        changePassword(this.id, this.old, this.newPassword).then(res => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
